<template origin="center center">
  <v-container id="status_offers" fluid class="pt-3">
    <v-row class="pl-4 pr-7 pt-3">
      <v-col cols="auto" class="pt-2">
        <div class="breadcrumb pa-1 ml-3">
          <Breadcrumbs />
        </div>
      </v-col>
      <v-spacer />
      <v-col cols="auto">
        <v-tooltip bottom>
          <template #activator="{ on, attrs }">
            <v-btn
              color="primary"
              small
              text
              icon
              class="ml-1 generate-excel"
              v-bind="attrs"
              v-on="on"
              @click.stop="modalGenerateExcel = true"
            >
              <v-icon class="mr-1" size="medium"> fa-solid fa-file-excel </v-icon>
            </v-btn>
          </template>
          <span>{{ $t("views.reports.availability.table.download_excel") }}</span>
        </v-tooltip>
      </v-col>
    </v-row>

    <v-row align="center" justify="center" no-gutters style="margin-left: 20px; margin-right: 20px">
      <v-col cols="auto">
        <TableComponent
          ref="StatusOffersTable"
          :searchP="searchField"
          @click:row="DetailsOffers"
          id="status_offers_table"
        />
      </v-col>
    </v-row>

    <v-dialog v-model="dialogDetails" width="1200px" persistent>
      <ModalDetailsOffers
        @closeForm="onCloseModalDetails"
        :idMonitoringOrder="idMonitoringOrder"
        :key="index"
      />
    </v-dialog>

    <v-dialog v-model="modalGenerateExcel" width="400px" persistent>
      <ModalExcelGeneratorComponent @closeForm="onCloseModalExcelGenerator" :key="index" />
    </v-dialog>
  </v-container>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
export default {
  data() {
    return {
      idMonitoringOrder: 0,
      index: 0,
      dialogDetails: false,
      modalGenerateExcel: false,
    };
  },
  components: {
    ModalDetailsOffers: () =>
      import(
        /* webpackChunkName: "ModalDetailsOffers" */ "@/components/views/Reports/StatusOffers/ModalDetailsOffers.vue"
      ),
    TableComponent: () =>
      import(/* webpackChunkName: "TableComponent" */ "./Table/TableComponent.vue"),
    ModalExcelGeneratorComponent: () =>
      import(
        /* webpackChunkName: "ModalExcelGeneratorComponent" */ "./ModalExcelGenerator/ModalExcelGeneratorComponent.vue"
      ),
    Breadcrumbs: () => import(/* webpackChunkName: "Breadcrumbs" */ "./breadcrumb/BreadCrumb.vue"),
  },
  name: "StatusOffers",
  metaInfo() {
    return {
      title: this.$t("components.layout.sidebar.status_of_offers"),
    };
  },
  computed: {
    ...mapGetters({
      user: "auth/user",
      search: "search/search",
    }),
    idCompany() {
      const userMap = JSON.parse(this.user);
      return userMap.user.idCompany;
    },
    searchField() {
      return this.search;
    },
  },
  watch: {
    token(newValue, oldValue) {
      if (newValue !== oldValue) {
        if (!this.token) window.location.href = process.env.VUE_APP_URL_LOGIN_SUITE;
      }
    },
    searchField(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.$nextTick(() => {
          this.onRealoadTable();
        });
      }
    },
  },
  methods: {
    ...mapActions("snackbar", ["showSnack"]),
    onRealoadTable() {
      this.$refs.StatusOffersTable.loadData();
    },
    DetailsOffers(item) {
      this.dialogDetails = true;
      this.$nextTick(() => {
        this.index += 1;
        this.idMonitoringOrder = item.idMonitoringOrder;
      });
    },
    onCloseModalDetails() {
      this.dialogDetails = false;
      this.$nextTick(() => {
        this.idMonitoringOrder = 0;
      });
    },
    onCloseModalExcelGenerator() {
      this.modalGenerateExcel = false;
    },
  },
};
</script>
<style scoped>
@import url("./StatusOffersStyle.scss");
</style>
